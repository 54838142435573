import * as React from "react";
import { ChessPiece } from "../models/chessPiece";
import { FC } from "react";
import "./PiecesList.scss";
import clsx from "clsx";
import bishopFilled from './chessIcons/bishop_filled.svg';
import kingFilled from './chessIcons/king_filled.svg';
import knightFilled from './chessIcons/knight_filled.svg';
import pawnFilled from './chessIcons/pawn_filled.svg';
import queenFilled from './chessIcons/queen_filled.svg';
import rookFilled from './chessIcons/rook_filled.svg';
import bishopOutlined from './chessIcons/bishop_outlined.svg';
import kingOutlined from './chessIcons/king_outlined.svg';
import knightOutlined from './chessIcons/knight_outlined.svg';
import pawnOutlined from './chessIcons/pawn_outlined.svg';
import queenOutlined from './chessIcons/queen_outlined.svg';
import rookOutlined from './chessIcons/rook_outlined.svg';

export type PiecesListProps = {
  pieces: ChessPiece[];
  currentPieceIndex: number;
};
export const PiecesList: FC<PiecesListProps> = ({
  pieces,
  currentPieceIndex,
}) => {
  function mapPieceToEmoji(piece: ChessPiece, isActive: boolean) {
    switch (piece) {
      case ChessPiece.Bishop:
        return isActive ? bishopFilled : bishopOutlined;
      case ChessPiece.King:
        return isActive ? kingFilled : kingOutlined;
      case ChessPiece.Knight:
        return isActive ? knightFilled : knightOutlined;
      case ChessPiece.Pawn:
        return isActive ? pawnFilled : pawnOutlined;
      case ChessPiece.Queen:
        return isActive ? queenFilled : queenOutlined;
      case ChessPiece.Rook:
        return isActive ? rookFilled : rookOutlined;
        return "";
    }
  }

  return (
    <div className="piecesList">
      {pieces.map((piece, index) => (
        <div
          key={index}
          className={clsx("piece", {
            "piece--used": index < currentPieceIndex,
            "piece--selected": index === currentPieceIndex,
          })}
        >
          <img src={mapPieceToEmoji(piece, index === currentPieceIndex)} height='80rem' width='80rem' />
        </div>
      ))}
    </div>
  );
};
