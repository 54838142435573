import React, { FC, useEffect, useState } from "react";
import "./App.css";
import { Grid, matchGrid } from "./models/grid";
import { ChessPiece } from "./models/chessPiece";
import { InteractiveGrid } from "./grid/InteractiveGrid";
import { PiecesList } from "./grid/PiecesList";
import confetti from "canvas-confetti";
import { puzzleOne } from "./puzzles/puzzleOne";
import { puzzleTwo } from "./puzzles/puzzleTwo";
import {
  tutorialOne,
  tutorialThree,
  tutorialTwo,
} from "./puzzles/tutorialPuzzles";
import { Puzzle } from "./models/puzzle";
import { Parallax } from "./parallax/parallax";
import { ClueList } from "./clues/ClueList";
import { Tutorial } from "./tutorial/Tutorial";
import {
  Button,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  Grid as MuiGrid,
  Container,
  Snackbar,
  Portal,
} from "@mui/material";
import { theme } from "./theme";
import { Help, Refresh, Settings, Share } from "@mui/icons-material";
import { SettingsMenu } from "./settings/Settings";
import { useLocation, useParams } from "react-router-dom";
import { Stats } from "./stats/Stats";
import { Flip, FlipBack, FlipFront } from "./_shared/Flip";
import { DailyStats } from "./stats/dailyStats";
import { Alert } from "@mui/lab";

const tutorialPuzzles = [tutorialOne, tutorialTwo, tutorialThree];
const puzzles = [puzzleOne, puzzleTwo];

type AppParams = {
  puzzleId?: string;
};

type AppProps = {
  tutorial?: boolean;
  hard?: boolean;
};

const App: FC<AppProps> = ({ hard, tutorial }) => {
  const { puzzleId } = useParams<AppParams>();
  const puzzleIdNum = parseInt(puzzleId || "0");
  const puzzleToRender = (tutorial ? tutorialPuzzles : puzzles)[puzzleIdNum];
  const location = useLocation();
  const [puzzle, setPuzzle] = useState<Puzzle>();
  const [puzzleStats, setPuzzleStats] = useState<DailyStats>(
    JSON.parse(localStorage.stats ?? "{}")
  );

  const [state, setState] = useState<Grid>();
  const [pieces, setPieces] = useState<ChessPiece[]>();
  const [currentPieceIndex, setCurrentPieceIndex] = useState<number>(0);
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successHasEverBeenOpen, setSuccessHasEverBeenOpen] = useState(false);

  const [kioskMode, setKioskMode] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const [startTime, setStartTime] = useState(new Date());
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tryAgainText, setTryAgainText] = useState("Try Again");

  useEffect(() => {
    snackbarMessage && setSnackbarOpen(true);
  }, [snackbarMessage]);

  useEffect(() => {
    if (localStorage.getItem("tutorial") !== "true") {
      setTutorialOpen(true);
      localStorage.setItem("tutorial", "true");
    }
  }, []);
  const handleStateChange = (newState: Grid) => {
    setState(newState);
    setCurrentPieceIndex((currentPieceIndex) => currentPieceIndex + 1);
  };

  useEffect(() => {
    setSuccess(false);
    setSuccessOpen(false);
    if (tutorial) {
      setPuzzle(puzzleToRender);
      setState(puzzleToRender.initialState);
      setPieces(puzzleToRender.pieces);
      return;
    }
    const today = new Date();
    setCurrentPieceIndex(0);
    setStartTime(new Date());
    let monthString = `${today.getFullYear()}${(1 + today.getMonth())
      .toString()
      .padStart(2, "0")}`;
    fetch(`/puzzles/${hard ? "hard/" : ""}${monthString}.json`).then((res) => {
      if (res.ok) {
        res.text().then((text) => {
          const result: Puzzle =
            JSON.parse(text)[
              `${monthString}${today.getDate().toString().padStart(2, "0")}`
            ];
          setPuzzle(result);
          setState(result.initialState);
          setPieces(result.pieces);
        });
      }
    });
  }, [location, hard, puzzleToRender, tutorial]);

  // Puzzle was just solved or was previously solved
  useEffect(() => {
    let dateStamp = `${startTime.getFullYear()}${
      startTime.getMonth() + 1
    }${startTime.getDate()}`;
    if (
      puzzleStats &&
      puzzleStats[dateStamp] &&
      puzzleStats[dateStamp][hard ? "hard" : "easy"] &&
        puzzle?.finalState &&
        !successHasEverBeenOpen
    ) {
      setState(puzzle?.finalState);
      setCurrentPieceIndex(pieces?.length ?? 0);
      setSuccess(true);
      setSuccessHasEverBeenOpen(true);
      !settingsOpen && setSuccessOpen(true);
    }
  }, [puzzleStats, puzzle, pieces, hard, settingsOpen, startTime, successHasEverBeenOpen]);

  // Puzzle was just solved
  useEffect(() => {
    if (
      state &&
      pieces &&
      puzzle &&
      currentPieceIndex === pieces.length &&
      matchGrid(state, puzzle.finalState) &&
      !success
    ) {
      const finishTime = new Date();

      let dateStamp = `${startTime.getFullYear()}${
        startTime.getMonth() + 1
      }${startTime.getDate()}`;
      const thisPuzzleStats = {
        completionTimeInSeconds:
          finishTime.getTime() / 1000 - startTime.getTime() / 1000,
        attempts,
      };
      let newPuzzleStats = {
        ...puzzleStats,
        [dateStamp]: {
          easy: puzzleStats[dateStamp]?.easy,
          hard: puzzleStats[dateStamp]?.hard,
          [hard ? "hard" : "easy"]: thisPuzzleStats,
        },
      };

      setTimeout(() => {
        setPuzzleStats(newPuzzleStats);
      }, 600);
      setSuccess(true);
      localStorage.setItem("stats", JSON.stringify(newPuzzleStats));
      confetti({
        particleCount: 200,
        spread: 200,
        origin: { y: 0.6 },
      });
    }
  }, [state, pieces, attempts, currentPieceIndex, hard, puzzle, puzzleStats, startTime, success]);

  const handleTryAgain = (hardReset?: boolean) => {
    setTryAgainText(`Attempt #${attempts + 1}`);
    const thisAttemptNum = attempts;
    if(hardReset) {
      setState(puzzle?.initialState);
      setAttempts(1);
      setCurrentPieceIndex(0);
      setSuccess(false);
      setPuzzleStats({});
      localStorage.stats = '{}';
      setSuccessOpen(false);
      setSuccessHasEverBeenOpen(false);
      setTryAgainText('Try Again');
      return;
    }
    setTimeout(() => {
      if (thisAttemptNum === 1) setTryAgainText("You got this");
      if (thisAttemptNum === 2) setTryAgainText("This time for sure");
      if (thisAttemptNum >= 3) setTryAgainText("Tough one this time");
    }, 400);
    setTimeout(() => {
      // TODO: Flip pieces first
      setState(puzzle?.initialState);
      setAttempts(hardReset ? 1 : thisAttemptNum + 1);
      setCurrentPieceIndex(0);
    }, 800);
    setTimeout(() => {
      setTryAgainText("Try Again");
    }, 1500);
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Parallax>
          <Portal>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={1500}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert severity="success">{snackbarMessage}</Alert>
            </Snackbar>
          </Portal>
          <Tutorial
            open={tutorialOpen}
            onClose={() => setTutorialOpen(false)}
          />
          <SettingsMenu
            open={settingsOpen}
            onClose={() => setSettingsOpen(false)}
            kioskMode={kioskMode}
            setKioskMode={setKioskMode}
          />
          <Stats
            open={successOpen}
            onClose={() => setSuccessOpen(false)}
            puzzleDate={startTime}
            stats={puzzleStats}
            pieces={pieces}
            hard={hard}
            setSnackbar={setSnackbarMessage}
          />
          <header className="App-header">
            {puzzle && pieces && state && (
              <Container sx={{ padding: 0 }}>
                <MuiGrid
                  container
                  sx={{ width: "100%" }}
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <MuiGrid item xs={3} sm={3}>
                    <div className={"App-logo"}></div>
                  </MuiGrid>
                  <MuiGrid item xs={6} sm={6}>
                    <Typography variant="h1">
                      {
                        <>
                          Chisel
                          <span className={"App-title--extended"}>
                            , a chess puzzle
                          </span>
                        </>
                      }
                    </Typography>
                  </MuiGrid>
                  <MuiGrid item xs={3} sm={3}>
                    <div className={"App-help"}>
                      {kioskMode && (
                        <IconButton onClick={() => handleTryAgain(true)}>
                          <Refresh />
                        </IconButton>
                      )}
                      <IconButton onClick={() => setSuccessOpen(true)}>
                        <Share />
                      </IconButton>
                      <IconButton onClick={() => setTutorialOpen(true)}>
                        <Help />
                      </IconButton>
                      <IconButton onClick={() => setSettingsOpen(true)}>
                        <Settings />
                      </IconButton>
                    </div>
                  </MuiGrid>
                </MuiGrid>
              </Container>
            )}
          </header>
          <div className="App-main">
            {puzzle && pieces && state && (
              <Stack spacing={2} alignItems="center">
                <ClueList puzzle={puzzle} tutorialShowing={tutorialOpen} />
                <InteractiveGrid
                  state={state}
                  onChange={(newState) => handleStateChange(newState)}
                  puzzle={puzzle}
                  pieces={pieces}
                  currentPieceIndex={currentPieceIndex}
                />
                <Flip flipped={!success && currentPieceIndex === pieces.length}>
                  <FlipFront>
                    <PiecesList
                      pieces={pieces}
                      currentPieceIndex={currentPieceIndex}
                    />
                  </FlipFront>
                  <FlipBack>
                    <Button
                      disableElevation
                      variant="contained"
                      onClick={() => handleTryAgain()}
                    >
                      <Typography variant="h5">{tryAgainText}</Typography>
                    </Button>
                  </FlipBack>
                </Flip>
              </Stack>
            )}
          </div>
          <footer className="App-footer">
            <a
              className={"App-footer-attribution"}
              href="https://monkeyjumplabs.com"
            >
              Built with ❤️ by
              <img
                alt={"MonkeyJump Labs"}
                height={42}
                src="/wordmark-transparent.png"
              />
            </a>
          </footer>
        </Parallax>
      </ThemeProvider>
    </div>
  );
};

export default App;
